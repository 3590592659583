import React, {useState} from 'react';
import { Redirect } from 'react-router';
import useInterval from "./useInterval";
function App() {
  const [countdown, setCountdown] = useState(5);

  const startCountDown = () => {
    let newCD = countdown - 1;
    console.log(newCD);
    if (newCD <=0){
      window.location.href = 'https://superstable.finance';
    }
    setCountdown(newCD);
  };

  useInterval(() => startCountDown(), 1000);
  return (
    <div className="flex flex-col h-full   bg-gray-200 text-gray-700">
      <div className="flex items-center text-center py-10 mt-10">
        <h1 className="text-6xl font-thin tracking-wider">
          Superbonds.finance has now become Superstable.finance. You are being re-directed to the valid domain
          in {countdown} seconds
          </h1>
      </div>

    </div>
  );
}

export default App;
